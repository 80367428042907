import React, { Component} from 'react';
import './style/footer.css';

class PageFooter extends Component {

  render() {
    return (
      <div className="pagefooter" >
          <p>(206) 353-9012</p>
          <p>
            Call today for a free estimate!
          </p>
          
    
      </div>
    )
  }

}

export default PageFooter;